import React from 'react';
import { Routes, BrowserRouter, HashRouter, Route, Switch, Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import ScrollToTop from './ScrollToTop'
import DefaultsideNav from './containers/DefaultLayout/DefaultsideNav';
// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Page404 = React.lazy(() => import('./Pages/Page404'));
const Page500 = React.lazy(() => import('./Pages/Page500'));
const Levels = React.lazy(() => import('./Pages/Level'));
const Signup = React.lazy(() => import('./Pages/UserSignup'));
const LiveTv = React.lazy(() => import('./Pages/GameDetail/LiveTv'));

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

const App = () => {
  const allowedOrigins = ['cache:https://sportsx9.com', 'https://sportsx9.com', 'cache:https://www.sportsx9.com', 'https://www.sportsx9.com', 'https://dev.sportsx9.com', 'https://theme.sportsx9.com'];
  const currentDomain = window.location.origin;
  const [showError, setShowError] = useState(!allowedOrigins.includes(currentDomain));

  useEffect(() => {
    if (showError) {
    }
  }, [showError]);
  return (
    <>
      {showError ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <h3><b>Something went wrong....</b></h3>
        </div>
      ) : (
        <>
          <BrowserRouter>
            <React.Suspense fallback={loading()}>
              <ScrollToTop />
              <Switch>
                <Route exact path="/Levels/:gameid" name="Level" render={props => <Levels {...props} />} />
                <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
                <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
                <Route exact path="/signup" name="userSignup" render={props => <Signup {...props} />} />
                <Route exact path="/LiveTv/:event_id" name="LiveTv" render={props => <LiveTv {...props} />} />
                <Route path="/" name="Home" render={props => <DefaultLayout {...props} />} />
              </Switch>
            </React.Suspense>
          </BrowserRouter>
        </>
      )}
    </>
  );
};

export default App;